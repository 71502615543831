/*jshint esversion: 6 */

const $ = require('jquery');

const $window = $(window);
const scrolledClass = 'scrolled';
const addClassToBody = true;
const addClassToHtml = false;
const disableOnMobile = false;
const breakpoint = 2;

function switchClass() {
  let scroll = $window.scrollTop();

  if (disableOnMobile && $window.width() < 786) {
    return;
  }

  if (scroll >= breakpoint) {
    if (addClassToBody) {
      $('body').addClass(scrolledClass);
    }
    if (addClassToHtml) {
      $('html').addClass(scrolledClass);
    }
  } else {
    if (addClassToBody) {
      $('body').removeClass(scrolledClass);
    }
    if (addClassToHtml) {
      $('html').removeClass(scrolledClass);
    }
  }
}


module.exports = function() {
  $window.on('scroll', switchClass);
  switchClass();
};
