/*jshint esversion: 6 */

import $ from 'jquery';
import bodyScrollClass from './bodyScrollClass';

$(() => {

  bodyScrollClass();

  /** Intro: */
  if ($('.intro').length < 1) {
    $('body').css({overflow: 'auto'});
  }

  setTimeout(() => {
    $('.intro').fadeOut(600, () => {
      $('body').css({overflow: 'auto'});
    });
  }, 1000);


  /** Freeze teaser text widths: */

  const updateElementsWidth = function() {
    $insideElements.each((index, element) => {
      const $element = $(element);
      $element.css({width: 'auto'});
      $element.css({width: $element.width()});
    });
  };

  const $window = $(window);
  const $insideElements = $('.teasers__teaser__inside');
  let currentWindowWidth = $window.width();
  $window.on('resize', () => {
    if (currentWindowWidth === $window.width()) {
      return;
    }
    currentWindowWidth = $window.width();
    updateElementsWidth();
  });

  updateElementsWidth();

});

